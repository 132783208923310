
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import _ from "lodash";
import { ApiRefundProcessing } from "@/core/api";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "add-entity-role-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    refundOrderNo: {
      type: String,
      default: "",
    },
    orderNo: {
      type: String,
      default: "",
    },
    amount: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form", "modal-hide"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const formRef = ref<null | HTMLFormElement>(null);
    const refundConfirmationRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const salesOrderNo = ref(props.orderNo);
    const refundAmount = ref(props.amount);
    const refundCurrency = ref(props.currency);

    watch(
      () => props.orderNo,
      (newValue) => {
        salesOrderNo.value = newValue;
      }
    );

    watch(
      () => props.amount,
      (newValue) => {
        refundAmount.value = newValue;
      }
    );

    watch(
      () => props.currency,
      (newValue) => {
        refundCurrency.value = newValue;
      }
    );

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      password: "",
    });

    const rules = ref({
      password: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const verifyIdentity = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.verifyPassword({
        password: formData.value.password,
      });
      loading.value = false;
      if (data.code == 0) {
        if (data.data.verified) {
          onfirmRefundAction();
        } else {
          Swal.fire({
            text: t("refundProcessing.modal.passwordWrong"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    const onfirmRefundAction = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.confirmRefund({
        id: props.id,
        refund_order_no: props.refundOrderNo,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm();
          updateList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          verifyIdentity();
        } else {
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const updateList = () => {
      emit("update-list");
      modalHide();
    };

    const modalShow = () => {
      showModal(refundConfirmationRef.value);
    };

    const modalHide = () => {
      hideModal(refundConfirmationRef.value);
    };

    onMounted(() => {
      //getUserOperatingEntity();

      modalShowListener(refundConfirmationRef.value, () => {
        setTimeout(() => {
          //
        }, 0);
      });
      modalHideListener(refundConfirmationRef.value, () => {
        // resetForm();
        emit("modal-hide");
      });
    });

    return {
      t,
      props,
      loading,
      formData,
      rules,
      formRef,
      refundConfirmationRef,
      modalShow,
      modalHide,
      submit,
      resetForm,
      updateList,
      salesOrderNo,
      refundAmount,
      refundCurrency,
    };
  },
});
