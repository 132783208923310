/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2024-06-28 16:54:21
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-07-04 09:39:50
 * @FilePath: \magicbean-saas-settlement\src\core\directive\interface\refundProcessing.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { CommonDateType, NumberOrString } from "../type/common";

export interface RefundOrderListItem {
  id: NumberOrString;
  refund_order_no: string;
  sales_order_no: string;
  pay_order_no: string;
  amount: number;
  currency: string;
  status: string;
  refund_reason: string;
  created_at: CommonDateType;
  refund_apply_time: CommonDateType;
  refund_time: CommonDateType;
}

export interface RefundOrderOverview {
  id: NumberOrString;
  refund_order_no: string;
  sales_order_no: string;
  pay_order_no: string;
  platform_refund_order_no: string;
  pay_center_refund_order_no: string;
  customer_firstname: string;
  customer_lastname: string;
  amount: NumberOrString;
  currency: string;
  created_at: CommonDateType;
  refund_apply_time: CommonDateType;
  refund_time: CommonDateType;
  balance_transaction_id: NumberOrString;
  commercial_invoice_no: string;
  credit_note_no: string;
  type: string;
  status: NumberOrString;
  warn: string;
  refund_reason: string;
  remark: string;
  manual_flag: number;
  relation_balance: string | null;
  items: Product[];
}
export interface Product {
  id: NumberOrString;
  brand: string;
  brand_article_no: string;
  original_color: string;
  color: string;
  size: string;
  name: string;
  name_en: string;
  image: string;
  guid: string;
  unit_price: string;
  total: string;
}

export const refundOrderOverviewInitData: RefundOrderOverview = {
  id: "",
  refund_order_no: "",
  sales_order_no: "",
  pay_order_no: "",
  platform_refund_order_no: "",
  pay_center_refund_order_no: "",
  customer_firstname: "",
  customer_lastname: "",
  amount: "",
  currency: "",
  created_at: "",
  refund_apply_time: "",
  refund_time: "",
  balance_transaction_id: "",
  commercial_invoice_no: "",
  credit_note_no: "",
  warn: "",
  type: "",
  status: "",
  refund_reason: "",
  remark: "",
  manual_flag: 0,
  relation_balance: null,
  items: [],
};
