
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import {
  setModuleBCN,
  CommonArrayToString,
  formatDate,
  formatDateTime,
  formatUtcDate,
} from "@/core/directive/function/common";
import { refundStatus } from "@/core/directive/type/refundProcessing";
import {
  FilterOption,
  SortCounter,
  SortOption,
  TableHeader,
  TaggingItem,
} from "@/core/directive/interface/common";
import {
  refundOrderOverviewInitData,
  RefundOrderOverview,
  Product,
} from "@/core/directive/interface/refundProcessing";
import {
  getRefundStatus,
  useComputedFn,
} from "@/core/directive/function/refundProcessing";
import { ApiRefundProcessing } from "@/core/api";
import { CommonDateType } from "@/core/directive/type/common";
import { MBDatatable } from "magicbean-saas-common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import RefundConfirmationModal from "./RefundConfirmationModal.vue";
import ManualProcessingModal from "./ManualProcessingModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";

export default defineComponent({
  name: "refund-order-overview-detail",
  components: {
    MBDatatable,
    ProductInfo,
    SkuInfo,
    RefundConfirmationModal,
    ManualProcessingModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const tableData = ref<Array<Product>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const sortOptions = ref<Array<SortOption>>([
      { field: "report_date", direction: "desc" },
    ]);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const tableHeader = computed(() => {
      let arr: TableHeader[] = [
        {
          name: t("refundProcessing.information.product"),
          key: "brand",
        },
        {
          name: t("refundProcessing.information.sku"),
          key: "brand_article_no",
        },
        {
          name: t("refundProcessing.information.guid"),
          key: "guid",
          sortable: false,
        },
        {
          name: t("refundProcessing.information.unitPrice"),
          key: "unit_price",
          sortable: false,
        },
        {
          name: t("refundProcessing.information.total"),
          key: "total",
          sortable: false,
        },
      ];
      return arr;
    });

    const refStatus = computed(() => {
      return refundOrderOverviewData.value.status;
    });

    const loading = ref(true);
    const refundOrderOverviewData = ref(
      Object.assign({}, refundOrderOverviewInitData)
    );

    const getRefundOrderOverviewData = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.getRefundOrderDetail({
        id: route.params.id,
        refund_order_no: route.params.refundOrderNo,
      });
      loading.value = false;
      if (data.code == 0) {
        refundOrderOverviewData.value = data.data.refund_order;
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    /**
     * @description: 渲染：状态
     */
    const getStatus = useComputedFn(getRefundStatus);

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getRefundOrderOverviewData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const customFormatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
      return formatDate(date, format);
    };

    const backToList = () => {
      router.replace({ path: "/refund-processing" });
    };

    const updateList = () => {
      getRefundOrderOverviewData();
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const onCancelButtonClicked = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.cancelRefund({
        id: route.params.id,
        refund_order_no: route.params.refundOrderNo,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          getRefundOrderOverviewData();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const executeRefundAction = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.executeRefund({
        id: route.params.id,
        refund_order_no: route.params.refundOrderNo,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          getRefundOrderOverviewData();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const onExecuteButtonClicked = () => {
      Swal.fire({
        text: t("refundProcessing.modal.confirmText"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          executeRefundAction();
        }
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getFormInfo();
    });

    return {
      t,
      tableData,
      tableHeader,
      currentPage,
      total,
      pageSize,
      getStatus,
      getRefundOrderOverviewData,
      refundOrderOverviewData,
      onColumnSort,
      onCurrentPageChange,
      onRowsPerPageChange,
      formatDate,
      formatDateTime,
      CommonArrayToString,
      loading,
      getFormInfo,
      customFormatDate,
      formatUtcDate,
      onCancelButtonClicked,
      onExecuteButtonClicked,
      refStatus,
      refundStatus,
      backToList,
    };
  },
});
