
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import _ from "lodash";
import { ApiBase, ApiRefundProcessing } from "@/core/api";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "add-entity-role-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    refundOrderNo: {
      type: String,
      default: "",
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-list", "reset-form", "modal-hide"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const formRef = ref<null | HTMLFormElement>(null);
    const manualProcessingRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      refund_time: "",
    });

    const rules = ref({
      refund_time: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const save = async () => {
      loading.value = true;
      const { data } = await ApiRefundProcessing.manualRefund({
        id: props.id,
        refund_order_no: props.refundOrderNo,
        refund_time: formData.value.refund_time,
      });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          resetForm();
          updateList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          save();
        } else {
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const updateList = () => {
      emit("update-list");
      modalHide();
    };

    const modalShow = () => {
      showModal(manualProcessingRef.value);
    };

    const modalHide = () => {
      hideModal(manualProcessingRef.value);
    };

    onMounted(() => {
      //getUserOperatingEntity();

      modalShowListener(manualProcessingRef.value, () => {
        setTimeout(() => {
          //
        }, 0);
      });
      modalHideListener(manualProcessingRef.value, () => {
        // resetForm();
        emit("modal-hide");
      });
    });

    return {
      t,
      props,
      loading,
      formData,
      rules,
      formRef,
      manualProcessingRef,
      modalShow,
      modalHide,
      submit,
      resetForm,
      updateList,
    };
  },
});
